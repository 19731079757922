.container-fluid {
    /*padding-left: 0;*/
    /*padding-right: 0;*/
}

.shadow {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .2);
}

.content {
    background-color: white;
    padding: 20px;
}

.bgWhiteImportant {
    background-color: white !important;
}

.link-btn-img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}