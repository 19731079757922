body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f9fa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-expandable-row-transition {
    opacity: 1;
    transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
}

.hide-row-transition {
    opacity: 0;
}